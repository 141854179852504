import * as React from 'react';

import { authClient } from '../lib/auth/client';
import { logger } from '../lib/default-logger';

import { getCurrentUser } from 'aws-amplify/auth';

export const UserContext = React.createContext(undefined);

export function UserProvider({ children }) {
  const [state, setState] = React.useState({
    user: null,
    unverifiedAccount: ({email: "",password: ""}),
    error: null,
    isLoading: true,
  });

  const checkSession = React.useCallback(async () => {
    try {
      const { data, error } = await authClient.getUser();

      if (error) {
        logger.error(error);
        setState((prev) => ({ ...prev, user: null, error: 'Something went wrong', isLoading: false }));
        return;
      }

      setState((prev) => ({ ...prev, user: data ?? null, error: null, isLoading: false }));
    } catch (err) {
      logger.error(err);
      setState((prev) => ({ ...prev, user: null, error: 'Something went wrong', isLoading: false }));
    }
  }, []);

  React.useEffect(() => {
    checkSession().catch((err) => {
      logger.error(err);
      // noop
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Expected
  }, []);

  async function resetPassword(params) {
    setState((prev) => ({ ...prev, unverifiedAccount: { email: params.email, password: "" } }));

    return await authClient.resetPassword(params)
  }

  async function confirmResetPassword(params) {
    setState((prev) => ({ ...prev, unverifiedAccount: { email: params.email, password: params.password } }));

    await authClient.confirmResetPassword({ 
      email: state.unverifiedAccount.email, 
      password: params.password, 
      code: params.code 
    })

    return await authClient.signInWithPassword({ email: state.unverifiedAccount.email, password: params.password })
  }

  async function signInWithOAuth(params){
    return await authClient.signInWithOAuth(params)
  }

  async function signUp(params) {
    setState((prev) => ({ ...prev, unverifiedAccount: { email: params.email, password: params.password } }));

    return await authClient.signUp(params)
  }

  async function signInWithPassword(params) {
    console.log(params)
    setState((prev) => ({ ...prev, unverifiedAccount: { email: params.email, password: params.password } }));

    console.log(state.unverifiedAccount.email)

    return await authClient.signInWithPassword(params)
  }

  async function confirmSignUp(params) {

    console.log(state.unverifiedAccount.email)

    const { confirmSignUpStep, error } = await authClient.confirmSignUp({ email: state.unverifiedAccount.email, code: params.code})

    await authClient.signInWithPassword({ email: state.unverifiedAccount.email, password: state.unverifiedAccount.password })

    return { confirmSignUpStep: confirmSignUpStep, error: error }
  }

  return <UserContext.Provider value={{ ...state, 
    checkSession, 
    signInWithPassword, 
    signUp, 
    confirmSignUp, 
    resetPassword, 
    signInWithOAuth,
    confirmResetPassword }}>{children}</UserContext.Provider>;
}

export const UserConsumer = UserContext.Consumer;
