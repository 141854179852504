import * as React from 'react';
import { useParams } from "react-router-dom"

import { config } from '../../../../config';
import { PropertyEdit } from '../../../../components/app/property/property-edit';

export const metadata = { title: `Edit property | Dashboard | ${config.site.name}` };

export default function Page() {
  const { id } = useParams();
  return (
    <PropertyEdit id={id}/>
  );
}
