import * as React from 'react';
import { config } from '../../../config';
import { Property } from '../../../components/app/property/property';

export const metadata = { title: `Properties | Dashboard | ${config.site.name}` }

export default function Page() {
  return (
    <Property/>
  );
}