'use client';

import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';
import { z as zod } from 'zod';
import { Controller, useForm } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';

import { useProperty } from '../../../../hooks/use-property';
import { paths } from '../../../../paths';

const schema = zod.object({
  property_name: zod.string().min(1, { message: 'Property name is required' }),
  address: zod.object({
    street: zod.any().or(zod.literal('')),
    apt: zod.any().or(zod.literal('')),
    neighborhood: zod.any().or(zod.literal('')),
    city: zod.any().or(zod.literal('')),
    county: zod.any().or(zod.literal('')),
    postal_code: zod.any().or(zod.literal('')),
  })
  
});

export function PropertyDetailsForm({
  property
}) {
  const navigate = useNavigate();

  const { createProperty, updateProperty } = useProperty();

  const [isPending, setIsPending] = React.useState(false);

  const initialValues = { 
    property_name: property?.property_name?? "",
    address: {
      street: property?.address?.street?? "",
      apt: property?.address?.apt?? "",
      neighborhood: property?.address?.neighborhood?? "",
      city: property?.address?.city?? "",
      county: property?.address?.county?? "",
      postal_code: property?.address?.postal_code?? ""
    }
  }

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ values: initialValues, resolver: zodResolver(schema) });

  const onSubmit = React.useCallback(
    async (values) => {
      setIsPending(true);

      const address = {
        street: values.address.street,
        apt: values.address.apt,
        neighborhood: values.address.neighborhood,
        city: values.address.city,
        county: values.address.county,
        postal_code: values.address.postal_code
      }

      const { data, error } = property ? 
        await updateProperty(
          property.property_id, 
          values.property_name,
          address
        )
      : 
        await createProperty(
          values.property_name,
          address,
        )
        
      if (error) {
        setError('root', { type: 'server', message: error });
        setIsPending(false);
        return;
      }

      navigate(paths.app.properties)
    },
    [property, navigate, setError]
  );


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader subheader="The information can be edited" title="Property" />
        <Divider />
        <CardContent>
          <Grid container spacing={3} sx={{ maxWidth: 'sm' }}>
            <Grid md={12} xs={12}>
              <Controller
                control={control}
                name="property_name"
                render={({ field }) => (
                  <FormControl fullWidth error={Boolean(errors.property_name)}>
                    <InputLabel>Property name</InputLabel>
                    <OutlinedInput {...field} label="Property name" />
                    {errors.property_name ? <FormHelperText>{errors.property_name.message}</FormHelperText> : null}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid md={12} xs={12}>
              <Divider variant="middle">Address</Divider>
            </Grid>

            <Grid md={12} xs={12}>
              <Controller
                control={control}
                name="address.street"
                render={({ field }) => (
                  <FormControl fullWidth error={Boolean(errors.address?.street)}>
                    <InputLabel>Street address</InputLabel>
                    <OutlinedInput {...field} label="Street address" />
                    {errors.address?.street ? <FormHelperText>{errors.address?.street.message}</FormHelperText> : null}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid md={12} xs={12}>
              <Controller 
                control={control}
                name="address.apt"
                render={({ field }) => (
                  <FormControl fullWidth error={Boolean(errors.address?.apt)}>
                    <InputLabel>Flat, suite, unit (if applicable)</InputLabel>
                    <OutlinedInput {...field} label="Flat, suite, unit (if applicable)" />
                    {errors.address?.apt ? <FormHelperText>{errors.address?.apt.message}</FormHelperText> : null}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid md={12} xs={12}>
              <Controller
                control={control}
                name="address.neighborhood"
                render={({ field }) => (
                  <FormControl fullWidth error={Boolean(errors.address?.neighborhood)}>
                    <InputLabel>Neighborhood (if applicable)</InputLabel>
                    <OutlinedInput {...field} label="Neighborhood (if applicable)" />
                    {errors.address?.neighborhood ? <FormHelperText>{errors.address?.neighborhood.message}</FormHelperText> : null}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid md={12} xs={12}>
              <Controller
                control={control}
                name="address.city"
                render={({ field }) => (
                  <FormControl fullWidth error={Boolean(errors.address?.city)}>
                    <InputLabel>City / municipality</InputLabel>
                    <OutlinedInput {...field} label="City / municipality" />
                    {errors.address?.city ? <FormHelperText>{errors.address?.city.message}</FormHelperText> : null}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid md={12} xs={12}>
              <Controller
                control={control}
                name="address.county"
                render={({ field }) => (
                  <FormControl fullWidth error={Boolean(errors.address?.county)}>
                    <InputLabel>County / governing district</InputLabel>
                    <OutlinedInput {...field} label="County / governing district" />
                    {errors.address?.county ? <FormHelperText>{errors.address?.county.message}</FormHelperText> : null}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid md={12} xs={12}>
              <Controller
                control={control}
                name="address.postal_code"
                render={({ field }) => (
                  <FormControl fullWidth error={Boolean(errors.address?.postal_code)}>
                    <InputLabel>Postal code</InputLabel>
                    <OutlinedInput {...field} label="Postal code" />
                    {errors.address?.postal_code ? <FormHelperText>{errors.address?.postal_code.message}</FormHelperText> : null}
                  </FormControl>
                )}
              />
            </Grid>



          </Grid>
          {errors.root ? <Alert color="error">{errors.root.message}</Alert> : null}

        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="contained" type="submit" >Save details</Button>
        </CardActions>
      </Card>
    </form>
  );
}
