import { 
    ApiError,
  get, post, put } from "aws-amplify/api"
import { amplifyClient } from '../amplify';

amplifyClient.configure()

class DeviceClient {

  async unlock(tenant_id, reservation_id) {
    var output = null
    var error = ''

    try {
         const operation = post({
          apiName: 'devices',
          path: '/ewelink/unlock',
          options: {
            body: { 
              tenant_id: tenant_id, 
              reservation_id: reservation_id
            }
          }
        })

        output = await operation.response
    } catch (err) {
        console.log(err)
        error = String(err)
    }
    
    return { data: output, error: error };
  }
}

export const deviceClient = new DeviceClient();
