import * as React from 'react';

import { config } from '../../../config';
import { ConfirmSignUpForm } from '../../../components/auth/confirm-sign-up-form';

export const metadata = { title: `Confirm Sign up | Auth | ${config.site.name}` };

export default function Page() {
  return (
    <ConfirmSignUpForm />
  );
}
