import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Download as DownloadIcon } from '@phosphor-icons/react/dist/ssr/Download';
import { Plus as PlusIcon } from '@phosphor-icons/react/dist/ssr/Plus';
import { Upload as UploadIcon } from '@phosphor-icons/react/dist/ssr/Upload';
import dayjs from 'dayjs';
import Grid from '@mui/material/Unstable_Grid2';

import { useProperty } from '../../../hooks/use-property';

import { config } from '../../../config';
import { IntegrationInfo } from '../integration/components/integration-info';
import { IntegrationEwelinkDetailsForm } from './components/integration-ewelink-details-form';
import { IntegrationHostexDetailsForm } from '../integration/components/integration-hostex-details-form';


import { z as zod } from 'zod';

export const metadata = { title: `Edit integration | Dashboard | ${config.site.name}` };

export function IntegrationEdit({
  id = ""
}) {
    const { loadIntegration: getIntegration } = useProperty();

    const [integration, setIntegration] = React.useState()

    React.useEffect(() => {
      async function fetchData() {
        const { data, error } = await getIntegration(id)

        var integration = (await data.body.json())[0]

        if (!integration)
        {
          integration = {
            integration_id: id
          }
        }

        console.log(integration)
        setIntegration(integration)
      }
        
      fetchData()
    }, []);

    return (
      <Stack spacing={3}>
      <div>
        <Typography variant="h4">Integration</Typography>
      </div>
      <Grid container spacing={3}>
        <Grid lg={4} md={6} xs={12}>
          <IntegrationInfo integration={integration} />
        </Grid>
        <Grid lg={8} md={6} xs={12}>
          {id == "hostex" ?
            <IntegrationHostexDetailsForm integration={integration} />
          :
            <IntegrationEwelinkDetailsForm integration={integration} />
          }
        </Grid>
      </Grid>
    </Stack>
  );
}

function applyPagination(rows, page, rowsPerPage) {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}
