import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Download as DownloadIcon } from '@phosphor-icons/react/dist/ssr/Download';
import { Plus as PlusIcon } from '@phosphor-icons/react/dist/ssr/Plus';
import { Upload as UploadIcon } from '@phosphor-icons/react/dist/ssr/Upload';
import dayjs from 'dayjs';

import { useProperty } from '../../../hooks/use-property';

import { config } from '../../../config';
import { PropertiesFilters } from '../../../components/app/property/components/properties-filters';
import { PropertiesTable } from '../../../components/app/property/components/properties-table';
import { paths } from '../../../paths';

export const metadata = { title: `Properties | Dashboard | ${config.site.name}` };

export function Property() {
    const navigate = useNavigate();

    const { listProperties } = useProperty();

    const page = 0;
    const rowsPerPage = 5;

    const [properties, setProperties] = React.useState([])

    const paginated = applyPagination(properties, page, rowsPerPage);

    React.useEffect(() => {
      async function fetchData() {
        const { data, error } = await listProperties()

        setProperties(await data.body.json())
      }
        
      fetchData()
    }, []);

    return (
        <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
          <Typography variant="h4">Properties</Typography>
        </Stack>
        <div>
          <Button 
            startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} 
            variant="contained"
            onClick={() => {
              navigate(paths.app.properties + "/create")
            }}
          >
            Add
          </Button>
        </div>
      </Stack>
      <PropertiesFilters />
      <PropertiesTable
        count={paginated.length}
        page={page}
        rows={paginated}
        rowsPerPage={rowsPerPage}
      />
    </Stack>
  );
}

function applyPagination(rows, page, rowsPerPage) {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}
