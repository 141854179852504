'use client';

import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';
import { z as zod } from 'zod';
import { Controller, useForm } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';

import { useProperty } from '../../../../hooks/use-property';
import { paths } from '../../../../paths';

const schema = zod.object({
  access_token: zod.string().min(1, { message: 'Access token is required' }),
});

export function IntegrationHostexDetailsForm({
  integration
}) {
  const navigate = useNavigate();

  const { createIntegration, updateIntegration } = useProperty();

  const [isPending, setIsPending] = React.useState(false);

  const initialValues = { 
    access_token: integration?.configuration?.access_token?? ""
  }

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ values: initialValues, resolver: zodResolver(schema) });

  const onSubmit = React.useCallback(
    async (values) => {
      setIsPending(true);

      const configuration = {
        access_token: values.access_token
      }

      const { data, error } = integration?.configuration ? 
        await updateIntegration(integration.integration_id, configuration)
      : 
        await createIntegration(integration.integration_id, configuration) 
        
      if (error) {
        setError('root', { type: 'server', message: error });
        setIsPending(false);
        return;
      }

      navigate(paths.app.integrations)
    },
    [integration, navigate, setError]
  );


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader subheader="The information can be edited" title="Integration" />
        <Divider />
        <CardContent>
          <Grid container spacing={3} sx={{ maxWidth: 'sm' }}>
            <Grid md={12} xs={12}>
              <Controller
                control={control}
                name="access_token"
                render={({ field }) => (
                  <FormControl fullWidth error={Boolean(errors.access_token)}>
                    <InputLabel>Access token</InputLabel>
                    <OutlinedInput {...field} label="Access token" fullWidth />
                    {errors.access_token ? <FormHelperText>{errors.access_token.message}</FormHelperText> : null}
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
          {errors.root ? <Alert color="error">{errors.root.message}</Alert> : null}

        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="contained" type="submit" >Save details</Button>
        </CardActions>
      </Card>
    </form>
  );
}
