import * as React from 'react';
import { Routes, Route, Outlet, Link } from "react-router-dom";

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import GlobalStyles from '@mui/material/GlobalStyles';

import { PropertyProvider } from '../../contexts/property-context';
import { AuthGuard } from '../../components/auth/auth-guard';
import { MainNav } from '../../components/app/layout/main-nav';
import { SideNav } from '../../components/app/layout/side-nav';

export default function Layout({ children }) {
  return (
    <AuthGuard>
      <PropertyProvider>

      <GlobalStyles
          styles={{
            body: {
              '--MainNav-height': '56px',
              '--MainNav-zIndex': 1000,
              '--SideNav-width': '280px',
              '--SideNav-zIndex': 1100,
              '--MobileNav-width': '320px',
              '--MobileNav-zIndex': 1100,
            },
          }}
        />
        <Box
          sx={{
            bgcolor: 'var(--mui-palette-background-default)',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            minHeight: '100%',
          }}
        >
          <SideNav />
          <Box sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column', pl: { lg: 'var(--SideNav-width)' } }}>
            <MainNav />
            <main>
              <Container maxWidth="xl" sx={{ py: '64px' }}>
                <Outlet />
                {/* {children} */}
              </Container>
            </main>
          </Box>
        </Box>

      </PropertyProvider>
    </AuthGuard>
  );
}



