import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Download as DownloadIcon } from '@phosphor-icons/react/dist/ssr/Download';
import { Plus as PlusIcon } from '@phosphor-icons/react/dist/ssr/Plus';
import { Upload as UploadIcon } from '@phosphor-icons/react/dist/ssr/Upload';
import dayjs from 'dayjs';
import Grid from '@mui/material/Unstable_Grid2';

import { useProperty } from '../../../hooks/use-property';

import { config } from '../../../config';
import { PropertyInfo } from '../../../components/app/property/components/property-info';
import { PropertyDetailsForm } from '../../../components/app/property/components/property-details-form';
import { z as zod } from 'zod';

export const metadata = { title: `Edit property | Dashboard | ${config.site.name}` };

export function PropertyEdit({
  id = ""
}) {
    const { loadProperty } = useProperty();

    const [property, setProperty] = React.useState()

    React.useEffect(() => {
      async function fetchData() {
        const { data, error } = await loadProperty(id)

        console.log((await data.body.json())[0])

        const property = (await data.body.json())[0]

        console.log(property)
        setProperty(property)
      }
        
      fetchData()
    }, []);

    return (
      <Stack spacing={3}>
      <div>
        <Typography variant="h4">Property</Typography>
      </div>
      <Grid container spacing={3}>
        <Grid lg={4} md={6} xs={12}>
          <PropertyInfo property={property} />
        </Grid>
        <Grid lg={8} md={6} xs={12}>
          <PropertyDetailsForm property={property} />
        </Grid>
      </Grid>
    </Stack>
  );
}

function applyPagination(rows, page, rowsPerPage) {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}
