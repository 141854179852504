export const paths = {
  home: '/',
  auth: { 
    signIn: '/auth/sign-in', 
    signUp: '/auth/sign-up', 
    confirmSignUp: '/auth/confirm-sign-up', 
    resetPassword: '/auth/reset-password',
    confirmResetPassword: '/auth/confirm-reset-password' },
  app: {
    dashboard: '/app/dashboard',
    account: '/app/account',
    reservations: '/app/reservations',
    properties: '/app/properties',
    customers: '/app/customers',
    integrations: '/app/integrations',
    settings: '/app/settings',
  },
  errors: { notFound: '/errors/not-found' },
} ;
