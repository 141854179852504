import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { date } from 'zod';
import { useUser } from '../../../../hooks/use-user';

export function ReservationInfo({
  reservation 
}) {
  const { user } = useUser();

  return (
    <Card>
      <CardContent>
        <Stack spacing={2} sx={{ alignItems: 'center' }}>
          <Stack sx={{ textAlign: 'center' }}>
            <Typography variant="body2">
              Reservation ID
            </Typography>
            <Typography variant="h5">
              {reservation?.reservation_id}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={4}>
            <Stack sx={{ textAlign: 'center' }}>
              <Typography variant="body2" >
                Check-in
              </Typography>
              <Typography variant="body2">
                {String(dayjs(reservation?.check_in_date).format("DD/MM/YYYY"))}
              </Typography>
            </Stack>

            <Stack sx={{ textAlign: 'center' }}>
              <Typography variant="body2">
                Check-out
              </Typography>
              <Typography variant="body2">
              {String(dayjs(reservation?.check_out_date).format("DD/MM/YYYY"))}
              </Typography>
            </Stack>
          </Stack>

          <Typography variant="body2">
            Guests: {reservation?.number_of_guests}
          </Typography>

          {reservation?.reservation_status === "checked_in" ?
            <Chip label="Checked-In" color="success" />
            :
            <Chip label="Pending Check-in" color="warning" />
          }
        </Stack>
        <Stack spacing={2} marginTop={4}>
          <Stack sx={{ textAlign: 'center' }}>
            <TextField
              label="Check-in URL"

              id="filled-hidden-label-small"
              value={"https://airbnbuilding.com/check-in?tenant=" + user?.["custom:tenant_id"] + "&code=" + reservation?.reservation_id}
              multiline
              fullWidth
              helperText="Share it with guests"
            >
            </TextField>
          </Stack>

        </Stack>

      </CardContent>
    </Card>
  );
}
