'use client';

import { 
  signIn, 
  fetchAuthSession, 
  getCurrentUser, 
  signOut, 
  signUp, 
  confirmSignUp, 
  AuthError, 
  resetPassword, 
  confirmResetPassword, 
  fetchUserAttributes,
  signInWithRedirect } from "aws-amplify/auth"
import { amplifyClient } from '../../lib/amplify';

amplifyClient.configure()

class AuthClient {
  async signUp(_) {
    // Make API request

    var signedUp = null
    var error = ''

    signedUp = await signUp({
      username: _.email,
      password: _.password,
      options: {
        userAttributes: {
          given_name: _.firstName,
          family_name: _.lastName,
          email: _.email,
        }
      },
    }).catch((err) => {
      error = String(err.message)
    })

    //No need for token right now
    // We do not handle the API, so we'll just generate a token and store it in localStorage.
    // const token = generateToken();
    // localStorage.setItem('custom-auth-token', token);

    return { signUpStep: signedUp?.nextStep.signUpStep, error: error };
  }

  async confirmSignUp(_){
    var confirmSignedUp = null
    var error = ''

    confirmSignedUp = await confirmSignUp({
      username: _.email,
      confirmationCode: _.code
    }).catch((err) => {
      console.log(err)
      error = String(err.message)
    })

    return { confirmSignUpStep: confirmSignedUp?.nextStep.signUpStep, error: error };
  }

  async signInWithOAuth(_) {
    var error = ''

    await signInWithRedirect({
      provider: 'Google'
    }).catch((err) => {
      console.log(err)
      error = String(err.message)
    })

    return { error: error }
  }

  async signInWithPassword(_) {
    var signInOutput = null
    var error = ''

    signInOutput = await signIn({
      username: _.email,
      password: _.password,
    }).catch((err) => {
      console.log(err)
      error = String(err.message)
    })
    
    return { signInStep: signInOutput?.nextStep.signInStep, error: error };
  }

  async resetPassword(_) {
    var resetPasswordOutput = null
    var error = ''

    resetPasswordOutput = await resetPassword({ 
      username: _.email 
    }).catch((err) => {
      console.log(err)
      error = String(err.message)
    });

    return { resetPasswordStep: resetPasswordOutput?.nextStep.resetPasswordStep, error: error };
  }

  async confirmResetPassword(_) {
    var error = ''

    await confirmResetPassword({ 
      username: _.email,
      confirmationCode: _.code,
      newPassword: _.password
    }).catch((err) => {
      console.log(err)
      error = String(err.message)
    });

    return { error: error };
  }

  async updatePassword(_) {
    return { error: 'Update reset not implemented' };
  }

  async getUser() {
    // Make API request
    const user = await fetchAuthSession({ forceRefresh: true });

    console.log(user)
    // We do not handle the API, so just check if we have a token in localStorage.
    //const token = localStorage.getItem('custom-auth-token');

    if (!user.tokens) {
       return { data: null };
    }

    const userAttributes = await fetchUserAttributes();
    //console.log(userAttributes)

    return { data: userAttributes };  //Fix this. For now we are not returning a User object
  }

  async signOut() {
    await signOut();

    //LocalStorage not used for now
    //localStorage.removeItem('custom-auth-token');

    return {};
  }
}

export const authClient = new AuthClient();
