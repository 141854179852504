import * as React from 'react';

import { PropertyContext } from '../contexts/property-context';

export function useProperty() {
  const context = React.useContext(PropertyContext);

  if (!context) {
    throw new Error('useProperty must be used within a UserProvider');
  }

  return context;
}
