import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { Download as DownloadIcon } from '@phosphor-icons/react/dist/ssr/Download';
import { Plus as PlusIcon } from '@phosphor-icons/react/dist/ssr/Plus';
import { Upload as UploadIcon } from '@phosphor-icons/react/dist/ssr/Upload';
import dayjs from 'dayjs';

import { config } from '../../../config';
import { IntegrationCard } from '../../../components/app/integration/integration-card';
import { IntegrationFilter } from '../../../components/app/integration/components/integration-filters';

export const metadata = { title: `Integrations | Dashboard | ${config.site.name}` };

const integrations = [
  {
    id: 'hostex',
    title: 'Hostex',
    description: 'Hostex is a property management platform. It automates the creation of reservations.',
    logo: '/assets/logo-hostex.jpeg',
    installs: 594,
    updatedAt: dayjs().subtract(12, 'minute').toDate(),
  },
  {
    id: 'ewelink',
    title: 'eWeLink',
    description: 'eWeLink, a universal smart home app that supports over 2000 hardware brands, allows you to manage home appliances remotely.',
    logo: '/assets/logo-ewelink.jpeg',
    installs: 625,
    updatedAt: dayjs().subtract(43, 'minute').subtract(1, 'hour').toDate(),
  },
  // {
  //   id: 'INTEG-004',
  //   title: 'Slack',
  //   description: 'Slack is a cloud-based set of team collaboration tools and services, founded by Stewart Butterfield.',
  //   logo: '/assets/logo-slack.png',
  //   installs: 857,
  //   updatedAt: dayjs().subtract(50, 'minute').subtract(3, 'hour').toDate(),
  // },
  // {
  //   id: 'INTEG-003',
  //   title: 'Lyft',
  //   description: 'Lyft is an on-demand transportation company based in San Francisco, California.',
  //   logo: '/assets/logo-lyft.png',
  //   installs: 406,
  //   updatedAt: dayjs().subtract(7, 'minute').subtract(4, 'hour').subtract(1, 'day').toDate(),
  // },
  // {
  //   id: 'INTEG-002',
  //   title: 'GitHub',
  //   description: 'GitHub is a web-based hosting service for version control of code using Git.',
  //   logo: '/assets/logo-github.png',
  //   installs: 835,
  //   updatedAt: dayjs().subtract(31, 'minute').subtract(4, 'hour').subtract(5, 'day').toDate(),
  // },
  // {
  //   id: 'INTEG-001',
  //   title: 'Squarespace',
  //   description: 'Squarespace provides software as a service for website building and hosting. Headquartered in NYC.',
  //   logo: '/assets/logo-squarespace.png',
  //   installs: 435,
  //   updatedAt: dayjs().subtract(25, 'minute').subtract(6, 'hour').subtract(6, 'day').toDate(),
  // },
];

export default function Page() {
  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
          <Typography variant="h4">Integrations</Typography>
        </Stack>
      </Stack>
      <IntegrationFilter />
      <Grid container spacing={3}>
        {integrations.map((integration) => (
          <Grid key={integration.id} lg={4} md={6} xs={12}>
            <IntegrationCard integration={integration} />
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Pagination count={1} size="small" />
      </Box>
    </Stack>
  );
}
