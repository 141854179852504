'use client';

import { 
    ApiError,
  get, post, put } from "aws-amplify/api"
import { amplifyClient } from '../../lib/amplify';

amplifyClient.configure()

class ReservationClient {
  
  async list(tenant_id) {
    var getOutput = null
    var error = ''

    try {
         const getOperation = get({
          apiName: 'reservations',
          path: '/',
          options: {
            queryParams: {
              tenant_id: tenant_id
            }
          }
        })

        getOutput = await getOperation.response

        console.log(getOutput)
    } catch (err) {
        console.log(err)
        error = String(err)
    }
    
    return { data: getOutput, error: error };
  }

  async load(tenant_id, reservation_id) {
    var getOutput = null
    var error = ''

    try {
         const getOperation = get({
          apiName: 'reservations',
          path: '/' + reservation_id,
          options: {
            queryParams: {
              tenant_id: tenant_id,
              reservation_id: reservation_id
            },
           
          },

        })

        getOutput = await getOperation.response
    } catch (err) {
        console.log(err)
        error = String(err)
    }
    
    return { data: getOutput, error: error };
  }

  async create(tenant_id, reservation_id, number_of_guests, check_in_date, check_out_date, property, guest){
    var output = null
    var error = ''

    try {
         const operation = post({
          apiName: 'reservations',
          path: '/',
          options: {
            body: { 
              tenant_id: tenant_id, 
              reservation_id: reservation_id,
              number_of_guests: number_of_guests,
              check_in_date: check_in_date,
              check_out_date: check_out_date,
              property: property,
              guest: guest
            }
          }
        })

        output = await operation.response
    } catch (err) {
        console.log(err)
        error = String(err)
    }
    
    return { data: output, error: error };
  }


  async update(tenant_id, reservation_id, number_of_guests, check_in_date, check_out_date, property, guest) {
    var output = null
    var error = ''

    try {
         const operation = put({
          apiName: 'reservations',
          path: '/',
          options: {
            body: { 
              tenant_id: tenant_id, 
              reservation_id: reservation_id,
              number_of_guests: number_of_guests,
              check_in_date: check_in_date,
              check_out_date: check_out_date,
              property: property,
              guest: guest
            }
          }
        })

        output = await operation.response
    } catch (err) {
        console.log(err)
        error = String(err)
    }
    
    return { data: output, error: error };
  }
}

export const reservationClient = new ReservationClient();
