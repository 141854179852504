import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import { z as zod } from 'zod';

import { paths } from '../../paths';
import { useUser } from '../../hooks/use-user';

const schema = zod.object({
  code: zod.string().min(1, { message: 'Code is required' }),
});

const defaultValues = { code: '', email: '' };

export function ConfirmSignUpForm() {
  const navigate = useNavigate();
  
  const { checkSession, confirmSignUp } = useUser();

  const [isPending, setIsPending] = React.useState(false);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ defaultValues, resolver: zodResolver(schema) });

  const onSubmit = React.useCallback(
    async (values) => {
      console.log('button')
      setIsPending(true);

      const { confirmSignUpStep, error } = await confirmSignUp(values);

      console.log(confirmSignUpStep)
      console.log(error)
    
      if (error) {
        setError('root', { type: 'server', message: error });
        setIsPending(false);
        return;
      }

      if (confirmSignUpStep !== "DONE")
      {
        setIsPending(false);

        return;
      }
        
      await checkSession?.();

      // UserProvider, for this case, will not refresh the router
      // After refresh, GuestGuard will handle the redirect
      navigate(0);
    },
    [checkSession, navigate, setError]
  );

  return (
    <Stack spacing={3}>
      <Stack spacing={1}>
        <Typography variant="h4">Confirm Sign up</Typography>
        <Typography color="text.secondary" variant="body2">
          Already have an account?{' '}
          <Link href={paths.auth.signIn} underline="hover" variant="subtitle2">
            Sign in
          </Link>
        </Typography>
      </Stack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Controller
            control={control}
            name="code"
            render={({ field }) => (
              <FormControl error={Boolean(errors.code)}>
                <InputLabel>Code</InputLabel>
                <OutlinedInput {...field} label="Code" />
                {errors.code ? <FormHelperText>{errors.code.message}</FormHelperText> : null}
              </FormControl>
            )}
          />
          {errors.root ? <Alert color="error">{errors.root.message}</Alert> : null}
          <Button disabled={isPending} type="submit" variant="contained">
            Sign up
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}
