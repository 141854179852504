import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { GearSix as GearSixIcon } from '@phosphor-icons/react/dist/ssr/GearSix';

import Typography from '@mui/material/Typography';

import dayjs from 'dayjs';

import { useUser } from '../../../hooks/use-user';
import { paths } from '../../../paths';

export function IntegrationCard({ integration }) {
  const { user } = useUser();
  const navigate = useNavigate();

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <CardContent sx={{ flex: '1 1 auto' }}>
        <Stack spacing={2}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Avatar src={integration.logo} variant="square" />
          </Box>
          <Stack spacing={1}>
            <Typography align="center" variant="h5">
              {integration.title}
            </Typography>
            <Typography align="center" variant="body1">
              {integration.description}
            </Typography>
          </Stack>
      </Stack>
        
      </CardContent>
      <Divider />
      <Stack direction="row" spacing={2} sx={{ alignItems: 'center', justifyContent: 'space-between', p: 2 }}>
        <Stack sx={{ alignItems: 'center' }} direction="row" spacing={1}>
          {/* <ClockIcon fontSize="var(--icon-fontSize-sm)" />
          <Typography color="text.secondary" display="inline" variant="body2">
            Updated {dayjs(integration.updatedAt).format('MMM D, YYYY')}
          </Typography> */}
        </Stack>
        <Stack sx={{ alignItems: 'center' }} direction="row" spacing={1}>
          <Button 
            startIcon={<GearSixIcon fontSize="var(--icon-fontSize-md)" />} 
            variant="contained"
            size='small'
            onClick={() => {
              navigate(paths.app.integrations + "/" + integration.id)
            }}
          >
            Configure
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
}
