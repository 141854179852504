'use client';
import { Amplify } from "aws-amplify"

class AmplifyClient {
  async configure() {

    var response = null

    if (process.env.REACT_APP_ENV?.trimEnd() == 'production') {
      response = {
        Auth: {
          Cognito: {
            userPoolId: "eu-west-1_p2U1hDZss",
            userPoolClientId: "6245u8if5vefpcpb71ulsituk",
            identityPoolId: "eu-west-1:99bfb249-fbfd-4747-b9f9-540dc8ac777d",
            loginWith: {
              email: true,
              oauth: {
                domain: 'airbnbuilding-prod-985539757842.auth.eu-west-1.amazoncognito.com',
                scopes: [
                  "phone",
                  "email",
                  "openid",
                  "profile",
                  "aws.cognito.signin.user.admin"
                ],
                redirectSignIn: ["http://localhost:3000/auth/sign-in"],
                redirectSignOut: ["http://localhost:3000/auth/sign-in"],
                responseType: 'code', 
              },
            },
            userAttributes: {
              email: {
                required: true,
              },
            },
            allowGuestAccess: true,
          },
        },
        API: {
          REST: {
            properties: {
              region: "eu-west-1",
              endpoint: "https://api.airbnbuilding.com/properties"
            },
            reservations: {
              region: "eu-west-1",
              endpoint: "https://api.airbnbuilding.com/reservations"
            },
            checkin: {
              region: "eu-west-1",
              endpoint: "https://api.airbnbuilding.com/check-in"
            },
            integrations: {
              region: "eu-west-1",
              endpoint: "https://api.airbnbuilding.com/integrations"
            },
            devices: {
              region: "eu-west-1",
              endpoint: "https://api.airbnbuilding.com/devices"
            },
          },
        },
      }
    }
    else
    {
      response = {
        Auth: {
          Cognito: {
            userPoolId: "eu-west-1_J7WIipNnl",
            userPoolClientId: "1rsjldqcle9gbgiknra3r7es2j",
            identityPoolId: "eu-west-1:7e93b131-2900-4edd-afc1-7e7afb940a47",
            loginWith: {
              email: true,
              oauth: {
                domain: 'airbnbuilding-dev-975050246515.auth.eu-west-1.amazoncognito.com',
                scopes: [
                  "phone",
                  "email",
                  "openid",
                  "profile",
                  "aws.cognito.signin.user.admin"
                ],
                redirectSignIn: ["http://localhost:3000/auth/sign-in"],
                redirectSignOut: ["http://localhost:3000/auth/sign-in"],
                responseType: 'code', 
              },
            },
            userAttributes: {
              email: {
                required: true,
              },
            },
            allowGuestAccess: true,
          },
        },
        API: {
          REST: {
            properties: {
              region: "eu-west-1",
              endpoint: "https://api.dev.airbnbuilding.com/properties"
            },
            reservations: {
              region: "eu-west-1",
              endpoint: "https://api.dev.airbnbuilding.com/reservations"
            },
            checkin: {
              region: "eu-west-1",
              endpoint: "https://api.dev.airbnbuilding.com/check-in"
            },
            integrations: {
              region: "eu-west-1",
              endpoint: "https://api.dev.airbnbuilding.com/integrations"
            },
            devices: {
              region: "eu-west-1",
              endpoint: "https://api.dev.airbnbuilding.com/devices"
            },
          },
        },
      }
    }

    console.log(response.API.REST.properties)

    Amplify.configure(response)

    return {};
  }
}

export const amplifyClient = new AmplifyClient();
