import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import { z as zod } from 'zod';
import LoadingButton from '@mui/lab/LoadingButton';
import LockIcon from '@mui/icons-material/LockOpen';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { paths } from '../../paths';
import { useUser } from '../../hooks/use-user';
import { useProperty } from '../../hooks/use-property';

import axios from 'axios';

export function CheckInForm() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { loadCheckIn, updateCheckIn, deviceUnlock, loadProperty } = useProperty();

  const [guests, setGuests] = React.useState();
  const [reservation, setReservation] = React.useState();
  const [property, setProperty] = React.useState();

  const [openDialog, setOpenDialog] = React.useState(false);

  const maxOpenGate = 3;

  const [loadingReservation, setLoadingReservation] = React.useState(false)
  const [loadingProperty, setLoadingProperty] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {    
    const tenant_id = searchParams.get('tenant')
    const reservation_id = searchParams.get('code')
    
    async function fetchData() {
      setLoadingReservation(true)

      const { data, error } = await loadCheckIn(tenant_id, reservation_id)
      
      if(!data)
      {      
        setLoadingReservation(false)
        return;
      }
        
      var checkIn = (await data.body.json())
      console.log(checkIn)
      //Checked in. Load property information
      if (checkIn.checked_in_guests?.length > 0 && checkIn.property_detail != null)
      {
        setLoadingProperty(true)

        const { data, error } = await loadProperty(checkIn.property_detail.property_id, checkIn.property_detail.tenant_id)
        
        const property = (await data.body.json())[0]
  
        console.log(property)
        setProperty(property)
          
        setLoadingProperty(false)
      }

      const initialGuests = Array(checkIn.number_of_guests).fill(null).map(() => ({
        name: '',
        rg: '',
        cpf: '',
        nameError: '',
        rgError: '',
        cpfError: ''
      }));

      setGuests(initialGuests);
      setReservation(checkIn)

      setLoadingReservation(false)
    }
      
    fetchData()
  }, [])

  const handleCheckIn = (event) => {
    event.preventDefault()

    const tenant_id = searchParams.get('tenant')
    const reservation_id = searchParams.get('code')

    const validForms = guests?.every(guest =>
      guest.name && !guest.nameError &&
      guest.rg && !guest.rgError &&
      guest.cpf && !guest.cpfError
    );

    if (validForms) {
      setLoading(true)

      const guestsMap = guests?.map(guest => ({
        name: guest.name,
        documents: [
          { type: "RG", value: guest.rg },
          { type: "CPF", value: guest.cpf }
        ]
      }))

      updateCheckIn(tenant_id, reservation_id, guestsMap)
        .then(res => {
          setReservation((prevState) => ({
            ...prevState,
            checked_in_guests: guestsMap
          }))
        })
        .catch(err => console.log(err))
        .finally(() => {
          setLoading(false)
        })
    }
  }

  async function handleUnlock() {
    setLoading(true)

    const tenant_id = searchParams.get('tenant')
    const reservation_id = searchParams.get('code')

    const { data, error } = await deviceUnlock(tenant_id, reservation_id)

    if(!data){
      setLoading(false)
      return;
    }

    var unlock = (await data.body.json())

    setReservation((prevState) => ({
      ...prevState,
      unlocked: unlock.unlocked
    }))
    
    setLoading(false)
  }

  const handleInputChange = (index, field, value) => {
    const newGuests = [...guests];
    newGuests[index][field] = value;

    if (field === 'name') {
      newGuests[index].nameError = nameValidator(value);
    }
    if (field === 'rg' || field === 'cpf') {
      newGuests[index][field + 'Error'] = nationalDocumentsValidator(value);
    }

    setGuests(newGuests);
  };

  const nameValidator = (value) => {
    if (value.length < 3) return "Nome deve ter pelo menos 3 letras";
    if (value.split(' ').length < 2) return "Nome completo deve ter pelo menos 2 nomes";
    if (!/^[a-zA-Z ]+$/.test(value))
      return "Nome deve conter apenas letras e espaços";
    return false;
  };

  const nationalDocumentsValidator = (value) => {
    if (value.length < 5) return "Documento deve ter pelo menos 5 números";
    return false;
  }

  const generateGuestsForm = () => {
    return guests?.map((guest, index) => (
        <Stack key={index + 1}>
          <Typography fontWeight={"bold"} marginTop={2} align='left'>
            Hóspede {index + 1}
          </Typography>
          <Stack>
            <TextField
              margin="normal"
              error={Boolean(guest.nameError)}
              helperText={guest.nameError}
              required
              fullWidth
              id={`name-${index}`}
              label="Nome completo"
              name="name"
              value={guest.name}
              onChange={e => handleInputChange(index, 'name', e.target.value)}
              onBlur={e => handleInputChange(index, 'name', e.target.value)}
            />
          </Stack>
          <Stack>
            <TextField
              margin="normal"
              error={Boolean(guest.rgError)}
              helperText={guest.rgError}
              required
              fullWidth
              name="rg"
              label="RG"
              id={`rg-${index}`}
              value={guest.rg}
              onChange={e => handleInputChange(index, 'rg', e.target.value)}
              onBlur={e => handleInputChange(index, 'rg', e.target.value)}
            />
          </Stack>
          <Stack>
            <TextField
              margin="normal"
              error={Boolean(guest.cpfError)}
              helperText={guest.cpfError}
              required
              fullWidth
              name="cpf"
              label="CPF"
              id={`cpf-${index}`}
              value={guest.cpf}
              onChange={e => handleInputChange(index, 'cpf', e.target.value)}
              onBlur={e => handleInputChange(index, 'cpf', e.target.value)}
            />
          </Stack>
        </Stack>
    ));
  };

  return (
    
      <Stack spacing={4}>
      <Stack spacing={2}>
        {reservation != null && reservation.checked_in_guests?.length > 0 ?
          <>
            <Typography variant="h4">
              Check-in realizado com sucesso!
            </Typography>
          </>
          :
          <>
            <Typography variant="h4">Check-in</Typography>
            <Typography color="text.secondary" variant="body2">
              Procedimento obrigatório de check-in para autorização de entrada no condomínio.
            </Typography>
          </>
        }
        </Stack>
        {loadingReservation ?
          // CARREGANDO RESERVA
          <Typography variant='body1'>
            Carregando sua reserva...
          </Typography>
          : reservation == null ?
            // RESERVA NAO EXISTENTE
            <Typography variant='body1'>
              Código de reserva não localizado ou reserva concluida!
            </Typography>
            : reservation != null && reservation.checked_in_guests?.length > 0 ?
            <>
              
              {reservation.property_detail != null &&
                <>
                  <Divider variant="middle">Propriedade</Divider>
                  <Stack spacing={2}>
                    <Typography variant="h6">
                      {reservation.property_detail?.property_name}
                    </Typography>
                    {loadingProperty ?
                      // CARREGANDO RESERVA
                      <Typography variant='body1' marginTop={2}>
                        Carregando sua reserva...
                      </Typography>
                      :
                      <Stack spacing={1}>
                        <Typography variant="body2" fontWeight={'bold'}>
                          Endereço
                        </Typography>
                        <Typography variant="body2">
                          {property?.address?.street}, {property?.address?.apt}, {property?.address?.neighborhood}, {property?.address?.city} - {property?.address?.county}, {property?.address?.postal_code}
                        </Typography>
                      </Stack>
                    }
                  </Stack>
                </>
              }
              <Divider variant="middle">Entrada remota</Divider>
              <Stack spacing={2}>
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  fullWidth
                  onClick={() => { setOpenDialog(true) }}
                  disabled={reservation.unlocked >= maxOpenGate}
                  endIcon={<LockIcon />}
                >
                  Abrir portão
                </LoadingButton>
                <Dialog
                  open={openDialog}
                  onClose={() => { setOpenDialog(false) }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Tem certeza que deseja acionar a abertura do portão?"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Utilizado {reservation.unlocked} vezes do total de {maxOpenGate} tentativas.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => { setOpenDialog(false) }}>Cancelar</Button>
                    <Button onClick={() => { handleUnlock(); setOpenDialog(false) }} autoFocus>
                      ABRIR PORTÃO
                    </Button>
                  </DialogActions>
                </Dialog>
                <Alert severity="warning">
                  Utilize apenas quando for abrir o portão pela primeira vez. <br></br>

                  Utilizado {reservation.unlocked} vezes do total de {maxOpenGate} tentativas.
                </Alert>
              </Stack>
            </>
            :
              // CHECK IN PENDENTE
              <>
                {generateGuestsForm()}

                <LoadingButton
                  loading={loading}
                  variant="contained"
                  type="submit"
                  fullWidth
                  sx={{ mt: 3, mb: 2 }}
                  onClick={handleCheckIn}
                >
                  Check-in
                </LoadingButton>

              </>
        }
      </Stack>
    
  );
}
