import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import { useUser } from '../../../../hooks/use-user';

export function IntegrationInfo({
  integration 
}) {
  const { user } = useUser();

  return (
    <Card>
      <CardContent>
        <Stack spacing={2} sx={{ alignItems: 'center' }}>
          <Stack sx={{ textAlign: 'center' }}>
            <Typography variant="body2">
              Integration ID
            </Typography>
            <Typography variant="h5">
              {integration?.integration_id}
            </Typography>
          </Stack>
        </Stack>

        {integration?.integration_id == "hostex" ?
          <Stack spacing={2} marginTop={4}>
            <Stack sx={{ textAlign: 'center' }}>
              <TextField
                label="Webhooks"
                slotProps={{
                  input: {
                    readOnly: true,
                  },
                }}
                id="filled-hidden-label-small"
                value={"https://api.airbnbuilding.com/reservations/hostex?tenant_id=" + user?.["custom:tenant_id"]}
                multiline
                fullWidth
                helperText="Add to hostex"
              >
              </TextField>
            </Stack>
          </Stack>
          :
          <></>
        }

          {/* <Stack spacing={1} sx={{ textAlign: 'center' }}>
            <Typography variant="h5">{integration?.integration_id}</Typography>
            <Typography color="text.secondary" variant="body2">
              {integration?.id}
            </Typography>
            <Typography color="text.secondary" variant="body2">
              {user.timezone}
            </Typography>
          </Stack> */}
        
      </CardContent>
    </Card>
  );
}
