import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Download as DownloadIcon } from '@phosphor-icons/react/dist/ssr/Download';
import { Plus as PlusIcon } from '@phosphor-icons/react/dist/ssr/Plus';
import { Upload as UploadIcon } from '@phosphor-icons/react/dist/ssr/Upload';
import dayjs from 'dayjs';

import { useProperty } from '../../../hooks/use-property';

import { config } from '../../../config';
import { ReservationFilter } from '../../../components/app/reservation/components/reservation-filter';
import { ReservationTable } from '../../../components/app/reservation/components/reservation-table';
import { paths } from '../../../paths';

export const metadata = { title: `Reservations | Dashboard | ${config.site.name}` };

export function Reservation() {
  const navigate = useNavigate();

    const { listReservations, listProperties } = useProperty();

    const page = 0;
    const rowsPerPage = 5;

    const [reservations, setReservations] = React.useState([])
    
    const paginated = applyPagination(reservations, page, rowsPerPage);

    React.useEffect(() => {
      console.log('i fire once');
      
      async function fetchData() {
        const { data, error } = await listReservations()
        setReservations(await data.body.json())
      }

      fetchData()
    }, []);

    return (
        <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
          <Typography variant="h4">Reservations</Typography>
        </Stack>
        <div>
          <Button 
            startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} 
            variant="contained"
            onClick={() => {
              navigate(paths.app.reservations + "/create")
            }}
          >
            Add
          </Button>
        </div>
      </Stack>
      <ReservationFilter />
      <ReservationTable
        count={paginated.length}
        page={page}
        rows={paginated}
        rowsPerPage={rowsPerPage}
      />
    </Stack>
  );
}

function applyPagination(rows, page, rowsPerPage) {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}
