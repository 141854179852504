import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { Pen as PenIcon } from '@phosphor-icons/react/dist/ssr/Pen';

import { useSelection } from '../../../../hooks/use-selection';
import { paths } from '../../../../paths';

function noop() {
  // do nothing
}

export function PropertiesTable({
  count = 0,
  rows = [],
  page = 0,
  rowsPerPage = 0,
}) {
  const navigate = useNavigate();
  
  return (
    <Card>
      <Box sx={{ overflowX: 'auto' }}>
        <Table sx={{ minWidth: '800px' }}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Property ID</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow hover key={row.property_id} >
                  <TableCell>
                    <Stack sx={{ alignItems: 'center' }} direction="row" spacing={2}>
                      <Avatar src={row.picture} />
                      <Typography variant="subtitle2">{row.property_name}</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>{row.property_id}</TableCell>
                  <TableCell>
                    <Button 
                      startIcon={<PenIcon fontSize="var(--icon-fontSize-md)" />} 
                      variant="outlined"
                      onClick={() => {
                        navigate(paths.app.properties + "/" + row.property_id)
                      }}
                    >
                      Edit
                    </Button>

                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      <Divider />
      <TablePagination
        component="div"
        count={count}
        onPageChange={noop}
        onRowsPerPageChange={noop}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
}
